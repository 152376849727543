<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskLinkTypeCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    const data = this.params && this.params.data? this.params.data : null;
    const type = data && data.type != null? data.type : null;
    this.value = type? this.params.context.componentParent.$t(`task_link_type.${type}`) : null;
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
